import { combineReducers } from "redux";

const userCompanies = (state = [], action) => {
    switch (action.type) {
        case "USER_COMPANIES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const usersInCompany = (state = [], action) => {
    switch (action.type) {
        case "SET_COMPANY_USERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const usersInCompanyMenu = (state = [], action) => {
    switch (action.type) {
        case "SET_MENU_COMPANY_USERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const userRole = (state = [], action) => {
    switch (action.type) {
        case "SET_USER_ROLE":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allRoles = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_ROLES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allProsCompany = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_PROS_COMPANY":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allCompanyMessages = (state = [], action) => {
    switch (action.type) {
        case "COMPANIES_MESSAGES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allCompanyInvoices = (state = [], action) => {
    switch (action.type) {
        case "COMPANIES_INVOICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const allBotmarkInvoices = (state = [], action) => {
    switch (action.type) {
        case "BOTMARK_INVOICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const CompanyPrices = (state = [], action) => {
    switch (action.type) {
        case "COMPANY_PRICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const BotmarkPrices = (state = [], action) => {
    switch (action.type) {
        case "BOTMARK_PRICES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}
const allCompanies = (state = [], action) => {
    switch (action.type) {
        case "SET_ALL_COMPANIES":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const selectedCompany = (state = {}, action) => {
    switch (action.type) {
        case "SET_SELECTED_COMPANY":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

const selectedCompanyUsers = (state = [], action) => {
    switch (action.type) {
        case "SET_SELECTED_COMPANY_USERS":
            state = action.payload;
            return state;
        default:
            return state;
    }
}

export default combineReducers({
    userCompanies,
    usersInCompany,
    userRole,
    allRoles,
    allProsCompany,
    allCompanyMessages,
    CompanyPrices,
    BotmarkPrices,
    allCompanyInvoices,
    allCompanies,
    usersInCompanyMenu,
    allBotmarkInvoices,
    selectedCompany,
    selectedCompanyUsers
});