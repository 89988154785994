import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
} from 'react-feather';
import RulerNotesModal from './RulerInfoModal';
import RulerCompanyModal from './RulerCompanyModal';
import RulerNumModal from './RulerNumModal';
import RulerDateModal from './RulerWarrantyModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const RulerDataGrid = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    // const role = useSelector((state) => state.CompanyReducer.userRole);
    // const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    // const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const allRulers = useSelector((state) => state.RulerReducer.allRulers);
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const [open3, setOpen3] = React.useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [open4, setOpen4] = React.useState(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);
    const [open5, setOpen5] = React.useState(false);
    const handleOpen5 = () => setOpen5(true);
    const handleClose5 = () => setOpen5(false);

    function showInfo(cellValues) { // hadles the double click to bring up modal to edit ruler notes
        setRowItem({
            id: cellValues.row.id,
            rulerNotes: cellValues.row.rulerNotes,
            rulerSerialNumber: cellValues.row.rulerSerialNumber,
            userId: cellValues.row.companyId,
            warrantyDate: cellValues.row.warrantyDate
        })
        handleOpen1();
    };
    function editCompany(cellValues) { // hadles the double click to bring up modal to edit company assigned to ruler
        setRowItem({
            id: cellValues.row.id,
            rulerNotes: cellValues.row.rulerNotes,
            rulerSerialNumber: cellValues.row.rulerSerialNumber,
            userId: cellValues.row.companyId,
            warrantyDate: cellValues.row.warrantyDate
        })
        handleOpen3();
    };
    function editRulerNum(cellValues) { // hadles the double click to bring up modal to edit to ruler #
        setRowItem({
            id: cellValues.row.id,
            rulerNotes: cellValues.row.rulerNotes,
            rulerSerialNumber: cellValues.row.rulerSerialNumber,
            userId: cellValues.row.companyId,
            warrantyDate: cellValues.row.warrantyDate
        })
        handleOpen4();
    };
    function editRulerDate(cellValues) { // hadles the double click to bring up modal to edit to ruler #
        setRowItem({
            id: cellValues.row.id,
            rulerNotes: cellValues.row.rulerNotes,
            rulerSerialNumber: cellValues.row.rulerSerialNumber,
            userId: cellValues.row.companyId,
            warrantyDate: cellValues.row.warrantyDate
        })
        handleOpen5();
    };

    const columns = [
        { field: 'rulerSerialNumber', headerName: 'Ruler#', flex: .5, headerClassName: 'header', 
            renderCell: (cellValues) => {
                return(<Button onClick={() => editRulerNum(cellValues)}>{cellValues.row.rulerSerialNumber}</Button>)
            }
        },
        { field: 'companyName', headerName: 'Company', flex: .5, headerClassName:'header',
            renderCell: (cellValues) => {
                if(cellValues.row.companyName){
                    return(<Button onClick={() => editCompany(cellValues)}>{cellValues.row.companyName}</Button>)
                } else {
                    return(<Button onClick={() => editCompany(cellValues)}>N/A</Button>)
                }
            }
        },
        { field: 'warrantyDate', headerName: 'Warranty', flex: .5, headerClassName:'header',
            renderCell: (cellValues) => {
                let date = new Date(cellValues.row.warrantyDate);
                // return date.toLocaleString()
                if(cellValues.row.warrantyDate){
                    return(<Button onClick={() => editRulerDate(cellValues)}>{date.toLocaleDateString()}</Button>)
                } else {
                    return(<Button onClick={() => editRulerDate(cellValues)}>N/A</Button>)
                }
            }
        },
        { field: 'rulerNotes', headerName: 'Notes', flex: .75, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.rulerNotes){
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', }}>{cellValues.row.rulerNotes}</Button>)
                } else {
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>No Notes</Button>)
                }
            }
        }
    ];

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    useEffect(() => {
        dispatch({ type: 'GET_RULERS' })
    }, []);

    return (
        <Page title="Companies">
            <Box style={{paddingTop:"30px", paddingRight:'30px', height:'60vh'}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Rulers
                    </Typography>
                </Box>
                <DataGrid
                    columns={columns}
                    rows={allRulers}
                />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RulerNotesModal item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
                </Modal>
                <Modal
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RulerCompanyModal item={rowItem} style={style} handleClose3={handleClose3}/>
                </Modal>
                <Modal
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RulerNumModal item={rowItem} style={style} handleClose4={handleClose4}/>
                </Modal>
                <Modal
                    open={open5}
                    onClose={handleClose5}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RulerDateModal item={rowItem} style={style} handleClose5={handleClose5}/>
                </Modal>
            </Box>
        </Page>
    )
};

export default RulerDataGrid;