import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Tabs,
    Tab,
    TextField,
    OutlinedInput
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';

function CompanyInfoEditor(){
    const companyData = useSelector((state) => state.CompanyReducer.selectedCompany);
    const dispatch = useDispatch();

    const [name, setName] = React.useState(companyData?.name);
    const [street, setStreet] = React.useState(companyData?.street);
    const [city, setCity] = React.useState(companyData?.city);
    const [state, setState] = React.useState(companyData?.state);
    const [zip, setZip] = React.useState(companyData?.zip);
    const [country, setCountry] = React.useState(companyData?.country);
    const [email, setEmail] = React.useState(companyData?.email);
    const [accountingEmail, setAccountingEmail] = React.useState(companyData?.accountingEmail);
    const [phoneNumber, setPhoneNumber] = React.useState(companyData?.phoneNumber);

    let company = {
        Id: companyData?.id,
        Name: name,
        City: city,
        Country: country,
        State: state,
        Street: street,
        Zip: zip,
        ProcessExternal: companyData?.processExternal,
        Email: email,
        PhoneNumber: phoneNumber,
        CompanyStatus: companyData?.companyStatus,
        AccountingEmail: accountingEmail,
        CompanyRate: companyData?.companyRate
    };
    console.error(companyData)

    function onSubmit(){
        dispatch({ type: "UPDATE_COMPANY_OBJECT", payload: company});
    };

    useEffect(() => {
    }, [companyData]);

    return(
        <div style={{width:'50%', margin:5}}>
        <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
            <Label><b>Company Reference Id:</b> {companyData?.id}</Label>
        </div>
        <br/>
        <Form>
        <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
            <span><b>Contact Info:</b></span>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Label style={{alignContent:"center"}}>Company Name:</Label><TextField style={{width:'75%'}} value={name} onChange={(event)=>setName(event.target.value)}/>
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Label style={{alignContent:"center"}}>Company Email:</Label><TextField style={{width:'75%'}} value={email} onChange={(event)=>setEmail(event.target.value)}/>
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Label style={{alignContent:"center"}}>Accounting Email:</Label><TextField style={{width:'75%'}} value={accountingEmail} onChange={(event)=>setAccountingEmail(event.target.value)}/>
            </div>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                <Label style={{alignContent:"center"}}>Company Phone:</Label><TextField style={{width:'75%'}} value={phoneNumber} onChange={(event)=>setPhoneNumber(event.target.value)}/>
            </div>
        </div>
        <br/>
        <div style={{border:'solid darkGray 2px', width:"95%", padding:5, borderRadius:"5px"}}>
            <span><b>Address:</b></span>
            {companyData.name != 'Unassigned' &&
                <div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Label style={{alignContent:"center"}}>Street:</Label><TextField style={{width:'75%'}} value={street} onChange={(event)=>setStreet(event.target.value)}/>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Label style={{alignContent:"center"}}>City:</Label><TextField style={{width:'75%'}} value={city} onChange={(event)=>setCity(event.target.value)}/>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Label style={{alignContent:"center"}}>State:</Label><TextField style={{width:'75%'}} value={state} onChange={(event)=>setState(event.target.value)}/>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Label style={{alignContent:"center"}}>Country:</Label><TextField style={{width:'75%'}} value={country} onChange={(event)=>setCountry(event.target.value)}/>
                    </div>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                        <Label style={{alignContent:"center"}}>Zip:</Label><TextField style={{width:'75%'}} value={zip} onChange={(event)=>setZip(event.target.value)}/>
                    </div>
                </div>
            }
        </div>
        <Button variant="contained"
            fullWidth sx={{ marginTop: '5px', height: '3.4375em', padding: '16.5px 14px' }}
            color="primary"
            onClick={()=>onSubmit()}
            >
            Update Company Info
        </Button>
        </Form>
    </div>
    )
}

export default CompanyInfoEditor;