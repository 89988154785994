import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';

function RulerNumModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
  const [rulerNumber, setRulerNumber] = React.useState(item.rulerSerialNumber);

  let rulerInfo = {
    Id: item.id,
    RulerNotes: item.rulerNotes,
    RulerSerialNumber: rulerNumber,
    WarrantyDate: item.warrantyDate,
    UserId: item.userId
  }

  function submitNotes(){
    dispatch({ type: "UPDATE_RULER", payload: rulerInfo });

    setRulerNumber(item.rulerSerialNumber);
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Ruler Serial Number
            </Typography>
            <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p>
            <p style={{textAlign:'center'}}>Please enter the last 6 digits of the Serial Number exactly as it appears on the EvanG3 Electronic Detailer.</p>
            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
              <FormControl sx={{ m: 2 }} style={{}}>
                    <Input value={rulerNumber} onChange={(event)=>setRulerNumber(event.target.value)}/>
              </FormControl>
                <Button onClick={() => submitNotes()}>Update Ruler</Button>
            </div>
          </Box>
        </Box>
    )
}

export default RulerNumModal;