import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Backdrop,
  CircularProgress,
  Tab,
  Tabs,
  Card,
  CardHeader
} from "@mui/material";
import homeBanner from "../../images/Botmark-Banner-Design-With-Logos.jpg";
import DeanPhoto from "../../images/Dean-Photo.jpeg";
import ArnelPhoto from "../../images/Arnel-Photo.jpeg";
import Youtube from 'react-youtube';
import BlueRulerIcon from "../../images/BlueRulerIcon.png";
import RedPlanIcon from "../../images/RedPlanIcon.png";
import OrangeDeliversIcon from "../../images/OrangeDeliversIcon.png";
import BotmarkFullText from "../../images/BotmarkFullText.png";
import LayoutSpecialtiesLogo from "../../images/LayoutSpecialtiesLogo.png";

function HomePage(){
    const dispatch = useDispatch();
    const opts = {
        height: '425px',
        width: '100%',
    };

    let screenWidth = screen.width;

    const saConfigData = useSelector((state) => state.UserReducer.publicConfig);
    const [publicAnn, setPublicAnn] = React.useState('N/A');

    function initValues(){
        // for (let i = 0; i < saConfigData.length; i++){
            // if (saConfigData[i].configName == 'Public Announcements'){
                setPublicAnn(saConfigData.configValue)
            // }
        // }
    }

    React.useEffect(() => {
        dispatch({ type: 'FETCH_S_A_PUBLIC_CONF' });
    },[]);

    React.useEffect(() => {
        initValues();
    },[saConfigData]);

    return(
        <div>
            {screenWidth >= 900 ?
                <div>
                    <div style={{backgroundColor:'#212121'}}>
                        <img style={{width:'75%', boxShadow:" 1px 4px 8px rgba(0, 0, 0, 0.2)"}} src={homeBanner}/>
                    </div>
                    <div style={{justifyItems:'center', marginTop:'10px',}}>
                        <Typography textAlign={"left"} variant='h4'>
                            Announcements
                        </Typography>
                        <p style={{textAlign:'left'}}>{publicAnn}</p>
                    </div>
                </div>
            :
                <div>
                    <img style={{width:"100%"}} src={BotmarkFullText}/>
                    <div style={{justifyItems:'center', marginTop:'10px',}}>
                        <Typography textAlign={"left"} variant='h4'>
                            Announcements
                        </Typography>
                        <p style={{textAlign:'left'}}>{publicAnn}</p>
                    </div>
                    <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <img style={{width:"33%"}} src={RedPlanIcon}/>
                        <img style={{width:"33%"}} src={BlueRulerIcon}/>
                        <img style={{width:"33%"}} src={OrangeDeliversIcon}/>
                    </div>
                    <img style={{width:"50%"}} src={LayoutSpecialtiesLogo}/>
                </div>
            }
        </div>
    )
}

export default HomePage;