import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
    Container,
    TextField,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Select,
    Box,
    FormControl,
    InputLabel,
    Button,
    Tab,
    Tabs,
    inputAdornmentClasses,
    Menu,
    MenuItem
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from 'react-currency-format';
import './EstimateView.css';
import { useEffect } from "react";
import moment from 'moment';
import PointListTable from "./PointListTable";
// import ReactPDF from '@react-pdf/renderer'
// import {PDFViewer} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';

function BotmarkEstimateDataView({ specialPointTypeTotal, pointTypeTotal, botmarkEstimateObj, invoiceOption, botmarkTotal, company, selectedCustomer }) {

    console.error(specialPointTypeTotal)

    return (
        <Table>
            <TableHead>
                <TableRow><TableCell colSpan={7}></TableCell></TableRow>
                <TableCell className="estimateTableCellVariant">Item</TableCell>
                <TableCell className="estimateTableCellVariant">Description</TableCell>
                <TableCell className="estimateTableCellVariant">Qty</TableCell>
                <TableCell className="estimateTableCellVariant">U/M</TableCell>
                <TableCell className="estimateTableCellVariant">Rate</TableCell>
                <TableCell className="estimateTableCellVariant">Amount</TableCell>
                <TableCell className="estimateTableCellVariant"></TableCell>
            </TableHead>
            <TableBody>
                {/* {invoiceOption?.map((item, i) => (
                    <TableRow>
                        <TableCell className="estimateTableCellVariant">{item?.itemName}</TableCell>
                        <TableCell className="estimateTableCellVariant">{item?.description}</TableCell>
                        <TableCell className="estimateTableCellVariant">{item?.quantity}</TableCell>
                        <TableCell className="estimateTableCellVariant">{item?.unit}</TableCell>
                        {selectedCustomer == undefined ?
                            <TableCell className="estimateTableCellVariant">{company.companyRate}</TableCell>
                            :
                            <TableCell className="estimateTableCellVariant">{selectedCustomer.companyRate}</TableCell>
                        }
                        {selectedCustomer == undefined ?
                            <TableCell className="estimateTableCellVariant"> 
                                <CurrencyFormat 
                                    value={Number(item?.quantity) * company.companyRate}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                >
                                </CurrencyFormat>
                            </TableCell>
                            :
                            <TableCell className="estimateTableCellVariant"> 
                                <CurrencyFormat 
                                    value={Number(item?.quantity) * selectedCustomer.companyRate}
                                    displayType={'text'} thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                >
                                </CurrencyFormat>
                            </TableCell>
                        }
                        <TableCell className="estimateTableCellVariant"></TableCell>
                    </TableRow>
                ))} */}
                <TableRow>
                    <TableCell className="estimateTableCellVariant">BM Points</TableCell>
                    <TableCell className="estimateTableCellVariant">E+C+U</TableCell>
                    <TableCell className="estimateTableCellVariant">{pointTypeTotal}</TableCell>
                    <TableCell className="estimateTableCellVariant">per/point</TableCell>
                    {selectedCustomer == undefined ?
                        <TableCell className="estimateTableCellVariant">{company.companyRate}</TableCell>
                        :
                        <TableCell className="estimateTableCellVariant">{selectedCustomer.companyRate}</TableCell>
                    }
                    {selectedCustomer == undefined ?
                        <TableCell className="estimateTableCellVariant"> 
                            <CurrencyFormat 
                                value={Number(pointTypeTotal) * company.companyRate}
                                displayType={'text'} thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            >
                            </CurrencyFormat>
                        </TableCell>
                        :
                        <TableCell className="estimateTableCellVariant"> 
                            <CurrencyFormat 
                                value={Number(pointTypeTotal) * selectedCustomer.companyRate}
                                displayType={'text'} thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            >
                            </CurrencyFormat>
                        </TableCell>
                    }
                    <TableCell className="estimateTableCellVariant"></TableCell>
                </TableRow>
                { specialPointTypeTotal != 0 &&
                    <TableRow>
                       <TableCell className="estimateTableCellVariant">CL Points</TableCell>
                       <TableCell className="estimateTableCellVariant">Chargeable Special Points</TableCell>
                       <TableCell className="estimateTableCellVariant">{specialPointTypeTotal}</TableCell>
                       <TableCell className="estimateTableCellVariant">per/point</TableCell>
                       {selectedCustomer == undefined ?
                           <TableCell className="estimateTableCellVariant">{company.companyRate}</TableCell>
                           :
                           <TableCell className="estimateTableCellVariant">{selectedCustomer.companyRate}</TableCell>
                       }
                       {selectedCustomer == undefined ?
                           <TableCell className="estimateTableCellVariant"> 
                               <CurrencyFormat 
                                   value={Number(specialPointTypeTotal) * company.companyRate}
                                   displayType={'text'} thousandSeparator={true}
                                   prefix={'$'}
                                   decimalScale={2}
                                   fixedDecimalScale={true}
                               >
                               </CurrencyFormat>
                           </TableCell>
                           :
                           <TableCell className="estimateTableCellVariant"> 
                               <CurrencyFormat 
                                   value={Number(specialPointTypeTotal) * selectedCustomer.companyRate}
                                   displayType={'text'} thousandSeparator={true}
                                   prefix={'$'}
                                   decimalScale={2}
                                   fixedDecimalScale={true}
                               >
                               </CurrencyFormat>
                           </TableCell>
                       }
                       <TableCell className="estimateTableCellVariant"></TableCell>
                   </TableRow>
                }
                <TableRow>
                    <TableCell className="estimateTableCellVariant"></TableCell>
                    <TableCell className="estimateTableCellVariant"></TableCell>
                    <TableCell className="estimateTableCellVariant"></TableCell>
                    <TableCell className="estimateTableCellVariant"></TableCell>
                    <TableCell className="estimateTableCellVariant"><b>Total:</b></TableCell>
                    <TableCell className="estimateTableCellVariant">
                    {selectedCustomer == undefined ?
                            <CurrencyFormat 
                                value={Number(pointTypeTotal+specialPointTypeTotal) * company.companyRate}
                                displayType={'text'} thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                        :
                            <CurrencyFormat 
                                value={Number(pointTypeTotal+specialPointTypeTotal) * selectedCustomer.companyRate}
                                displayType={'text'} thousandSeparator={true}
                                prefix={'$'}
                                decimalScale={2}
                                fixedDecimalScale={true}
                            />
                    }
                    </TableCell>
                    <TableCell className="estimateTableCellVariant"></TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default BotmarkEstimateDataView;