import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import Toast from '../../components/Toast';
import fileDownload from 'js-file-download';
import getToken from '../../mixins/GetToken';
import SplitToken from '../../mixins/SplitToken';
import { useDispatch, useSelector } from "react-redux";

const sleep = ms => new Promise(r => setTimeout(r, ms));

const token = getToken();
const user = SplitToken();
// {headers: !token ? {} : { 'Authorization': `Bearer ${token}` }}

function* fetchProcessorVersion(){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        const fileVersion = yield axios.get(`/api/Account/GetProcessorVersion`, config);
        yield put({
            type:"SET_FILE_VERSION",
            payload: fileVersion
        })
    } catch (error) {
        console.log("Error with fetching file version:" + error);
    }
};

function* uploadFile(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    let fileInfo = []
    try {
        let done = false;
        // yield put({
        //     type: "FILE_LOADING",
        //     payload: true
        // });
        const formData = new FormData();
        formData.append("file", action.payload.file);
        formData.append("companyId", action.payload.companyId);
        formData.append("name", action.payload.name);
        const file = yield axios.post(`/api/File/FileUpload`, formData , config);
        yield put({
            type:"SET_FILE_POINTS",
            payload: file.data
        })
    } catch (error) {
        console.log("Upload file error:" + error)
        Toast.fire({ title: `${error}`, icon: 'error' });
        yield put({
            type: "FILE_LOADING",
            payload: false
        })
    }
};

function* postCountPost(action) {
    yield put({
        type: "SET_FILE_POINTS",
        payload: []
    })
    let done = false;
    let fileInfo = [];
    yield put({
        type: "FILE_LOADING",
        payload: true
    });
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        },
    };
    const data = action.payload;
    console.log(action.payload)
    try{
        const postedFile = yield axios.post(`/api/File/FileProcessor`, data, config);
        while (!done) {
            try {
                yield sleep(15000);
                const final = yield axios.get(`/api/File/GetFileInfo/${postedFile.data}`, config);
                fileInfo = final
                console.log(final.data.isProcessed)
                if ( final.data.isProcessed == 1 ) {
                    console.log(final.data.sequencingErrorCode)
                    yield put({
                        type: "VALIDATION_ERROR",
                        payload: final.data.sequencingErrorCode
                    })
                    done = true;
                }
            } catch (error) {
                console.log(error)
                Toast.fire({ title: 'Error in upload', icon: 'error' });
                yield put({
                    type: "FILE_LOADING",
                    payload: false
                })
                return;
            }
        }
        if (done) {
            console.log('done')
            const newInfo = yield axios.get(`/api/File/GetFileInfo/${postedFile.data}`, config);
            const validation = yield axios.get(`/api/File/DownloadValidationReport/${postedFile.data}`, config);
            const summary = yield axios.get(`/api/File/DownloadSummaryReport/${postedFile.data}`, config);
            if ( newInfo.data.sequencingErrorCode == 0 || newInfo.data.sequencingErrorCode == 1 ) {
                Toast.fire({ title: 'File Uploaded', icon: 'success' });
            }
            else {
                Toast.fire({ title: 'Error in file', icon: 'error' });
            }
            yield put({
                type: "SUMMARY_FILE",
                payload: summary.data
            })
            yield put({
                type: "VALIDATION_FILE",
                payload: validation.data
            })
            yield put({
                type: "VALIDATION_ERROR",
                payload: fileInfo.data.sequencingErrorCode
            })
            yield put({
                type: "VALIDATION_INFO",
                payload: fileInfo.data
            })
            yield put({
                type: "GET_COMPANY_FILES",
                payload: action.payload.companyId
            })
            yield put({
                type: "FILE_LOADING",
                payload: false
            })
            // yield put({
            //     type: "SET_FILE_POINTS",
            //     payload: []
            // })
        }   
    } catch (error) {
        console.log("Upload file error:" + error)
        Toast.fire({ title: `${error}`, icon: 'error' });
        yield put({
            type: "FILE_LOADING",
            payload: false
        })
    }
};

function* getValidation(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        console.log("ACTION", action.payload)
        yield put({ type: "REPORT_LOADING" })
        const validation = yield axios.get(`/api/File/DownloadValidationReport/${action.payload}`, config);
        const summary = yield axios.get(`/api/File/DownloadSummaryReport/${action.payload}`, config);
        const file = yield axios.get(`/api/File/GetFileInfo/${action.payload}`, config);

        console.log("VALIDATION",validation);
        console.log("SUMMARY",summary);
        console.log("FILE",file);

        yield put({
            type: "SUMMARY_FILE",
            payload: summary.data
        })
        yield put({
            type: "VALIDATION_FILE",
            payload: validation.data
        })
        yield put({
            type: "VALIDATION_ERROR",
            payload: file.data.sequencingErrorCode
        })
        yield put({
            type: "VALIDATION_INFO",
            payload: file.data
        })
        yield put({ type: "REPORT_DONE" })
    } catch (error) {
        console.log(error)
    }
};

function* downloadPaid(action) {
    const token = getToken();
    const user = SplitToken();
    const error = action.payload.sequencingErrorCode;
    const name = action.payload.name.split('.')[0];
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' ,
        }
    };
    const dwgConfig = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*',
            "responseType": 'blob' 
        }
    };

    var JSZip = require("jszip");

    const zip = new JSZip();

    if ( error == 404 ) {
        Toast.fire({ icon: 'error', title: 'Bad file' });
    }
    else {
        yield axios.get(`/api/File/DownloadBMK/${action.payload.id}`, config)
            .then((res) => { 
                let fileName = name+".bmk";

                zip.file(fileName, res.data);
            });
        yield axios.get(`/api/File/DownloadPaidTxt/${action.payload.id}`, config)
            .then((res) => { 
                let fileName = name+".txt";

                // fileDownload(res.data, fileName);
                zip.file(fileName, res.data);

            });
        ////* NOTE The DWG download/zip functionality works but there is a bug with zip and auth token further research needed to fix this *////
        try{ 
            yield axios.get(`/api/File/DownloadPaidDwg/${action.payload.id}`,
            // config,
            {responseType:'blob'},
            // dwgConfig
            )
            .then((res) => { 
                let fileName = name+".dwg";

                zip.file(fileName, res.data);
            });
        } catch{
            console.log("No Dwg file found.")
        }
        ////* NOTE The DWG download/zip functionality works but there is a bug with zip and auth token further research needed to fix this *////
        zip.generateAsync({type:"blob"}).then(function(content) {
                fileDownload(content, `${name}.zip`);
            });
    }
};

function* downloadValidation(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    const error = action.payload.sequencingErrorCode;
    const name = action.payload.name.split('.')[0];
    yield axios.get(`/api/File/DownloadValidationReport/${action.payload.id}`, config)
        .then((res) => fileDownload(res.data, `${name}-ValidationReport.txt`));
    yield axios.get(`/api/File/DownloadSummaryReport/${action.payload.id}`, config)
        .then((res) => fileDownload(res.data, `${name}-SequenceSummary.txt`));
    console.log(error)
    if ( error !== 0 && error !== 1 ) {
        console.log(error)
        //yield axios.get(`/api/File/DeleteFile/${action.payload.id}`);
        yield put({
            type: 'GET_COMPANY_FILES',
            payload: action.company
        })
    }
};

function* testUpload() {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        yield put({
            type: "FILE_LOADING",
            payload: true
        });
        console.log('done')
        const newFile = yield axios.get(`/api/File/Download/d245a4e4-1ad9-44f4-b366-bf735db11046`);
        const file = newFile.data.split('\n');
        yield put({
            type: "FINAL_FILE",
            payload: file
        })
        yield put({
            type: 'FILE_LOADING',
            payload: false
        })
    } catch (error) {
        console.log(error)
    }
};

function* getAllFiles(action) {
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    console.error("HERE",action.payload)
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        if (action.payload.page!= null ){
            const files = yield axios.get(`/api/File/GetAllFilesForCompany/${action.payload.companyId}/${action.payload.customerId}/${action.payload.page}/${action.payload.pageSize}`, config);
            yield put({
                type: 'SET_COMPANY_FILES',
                payload: files.data
            });
            yield put({
                type: 'SET_IS_LOADING',
                payload: false
            });
        } else {
            const files = yield axios.get(`/api/File/GetAllFilesForCompany/${action.payload}/00000000-0000-0000-0000-000000000000/0/25`, config);
            yield put({
                type: 'SET_COMPANY_FILES',
                payload: files.data
            });
            yield put({
                type: 'SET_IS_LOADING',
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    }
};

// function* getAllFiles(action) {
//     try {
//         const files = yield axios.get(`/api/File/GetAllFilesForCompany`, action.payload);
//         yield put({
//             type: 'SET_COMPANY_FILES',
//             payload: files.data
//         })
//     } catch (error) {
//         console.log(error)
//     }
// }

function* getAllPaidFiles(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const files = yield axios.get(`/api/File/GetAllPlansThatArePaidFor/${action.payload}`, config);
        yield put({
            type: "SET_COMPANY_PAID_FILES",
            payload: files.data
        })
    } catch (error) {
        console.log(error)
    }
};

function* acceptFile(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const accept = yield axios.post(`/api/File/AcceptFilePlan/${action.payload.id}/${action.payload.acceptance}`, {}, config);
        if (accept.status === 200 && action.payload.acceptance == 1){
            Toast.fire({
                title: 'File accepted',
                icon: 'success'
            })
            yield put({ type: "VALIDATION_RESET" });
            yield put({type: "GET_COMPANY_FILES", payload: action.payload.company})
        }
        if (accept.status === 200 && action.payload.acceptance == 0){
            Toast.fire({
                title: 'Plan Not Accepted, Botmark Notified',
                icon: 'success'
            })
            yield put({ type: "VALIDATION_RESET" });
            yield put({type: "GET_COMPANY_FILES", payload: action.payload.company})
        }
        if (accept.status === 200 && action.payload.acceptance == 2){
                Toast.fire({
                    title: 'Plan Rejected and Deleted',
                    icon: 'success'
                })
                yield put({ type: "VALIDATION_RESET" });
                yield put({type: "GET_COMPANY_FILES", payload: action.payload.company})
        }
    } catch (error) {
        Toast.fire({
            title: 'Error with file',
            icon: 'error',
        })
        console.log(error)
    }
};

function* buyFile(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const pay = yield axios.post(`/api/File/MarkFileAsPaid/${action.payload}`, {}, config);
        Toast.fire({
            title: 'Thank you for purchase',
            icon: 'success'
        })
        yield put({
            type: 'GET_COMPANY_FILES',
            payload: action.company
        })
    } catch (error) {
        Toast.fire({
            title: 'Error processing transaction'
        })
        console.log(error)
    }
};

function* changeFileName(action){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        const fileNamechange = yield axios.put(`/api/File/UpdateFileName/${action.payload.id}`, action.payload, config);

        if (fileNamechange.status === 200){
            Toast.fire({
            title: 'File Name Updated.',
            icon: 'success'
        })
        yield put({
            type: 'GET_COMPANY_FILES',
            payload: action.company
        })
        yield put({
            type: 'GET_VALIDATION_FILE',
            payload: action.payload.id
        })
        }} 
    catch (error){
        Toast.fire({
        title: 'Error with file name change. File name may already be in use, try again.',
        icon: 'error'
        })
        console.log(error)
    }
};

function* uploadPDFFile(action) {
    const token = getToken();
    const user = SplitToken();
    let fileInfo = [];
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        let done = false;
        yield put({
            type: "FILE_LOADING",
            payload: true
        });
        const formData = new FormData();
        formData.append("file", action.payload.file);
        formData.append("companyId", action.payload.companyId);
        formData.append("name", action.payload.name);
        formData.append("PDFType", action.payload.PDFType);
        const file = yield axios.post(`/api/PDFFile/PDFFileUpload`, formData , config);
        if (file.status === 200){
            Toast.fire({ title: 'PDF Uploaded', icon: 'success' });
            action.setFile([]);
            action.setAgType();
            action.navigate("/upload")
        }

    } catch (error) {
        console.log("Upload file error:" + error)
        Toast.fire({ title: 'Error in upload', icon: 'error' });
        yield put({
            type: "FILE_LOADING",
            payload: false
        })
    }
};

function* getPDFFile(action) {
    ////* NOTE The PDF download functionality works but there is a bug with auth token further research needed to fix this *////
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    const pdfConfig = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*',
            "responseType": 'blob' 
        }
    };
    ////* NOTE The PDF download functionality works but there is a bug with auth token further research needed to fix this *////
    try {
        // console.log("ACTION", action.payload)
        yield put({ type: "REPORT_LOADING" })
        const PDFFile = yield axios.get(`/api/PDFFile/DownloadPDFFile/${action.payload}`,
        //  pdfConfig,
         {responseType: 'blob'}
         );

        if (PDFFile.status === 200? PDFFile.data : null){

            const pdfFile = new Blob(
                [PDFFile.data], 
                {type: 'application/pdf'}
            );
            const PDFURL = URL.createObjectURL(pdfFile);
            // window.open(PDFURL);
            
            action.setPdfUrl(`${PDFURL}`)
                yield put({
                    type: "PDF_FILE",
                    payload: PDFURL
            })
        }

        yield put({ type: "REPORT_DONE" })
    } catch (error) {
        console.log(error)
    }
};

function* updateFileWithCustomer(action){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try{
        const updateFile = yield axios.put(`/api/File/UpdateFileWithCustomerInfo/${action.payload.file}/${action.payload.customer}`, config);

        if (updateFile.status === 200){
            Toast.fire({
            title: 'Customer Set.',
            icon: 'success'
        })
        yield put({
            type: 'GET_COMPANY_FILES',
            payload: action.payload.userCompany
        })
        }} 
    catch (error){
        Toast.fire({
        title: 'Error with setting a customer to this Project.',
        icon: 'error'
        })
        console.log(error)
    }
};

function* getAllFilesforCustomer(action) {
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const files = yield axios.get(`/api/File/GetAllFilesForCustomer/${action.payload.companyId}/${action.payload.customerId}/${action.payload.page}/${action.payload.pageSize}`, config);
        console.log("HERE", user.id)
        yield put({
            type: 'SET_CUSTOMER_FILES',
            payload: files.data
        })
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    } catch (error) {
        console.log(error)
        yield put({
            type: 'SET_IS_LOADING',
            payload: false
        });
    }
};

function* uploadVideoUrl(action){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const url = yield axios.post(`/api/PDFFile/UploadVideoUrl/${action.payload.vidType}`, action.payload.vidUrl, config);
        // yield put({
        //     type: 'SET_CUSTOMER_FILES',
        //     payload: files.data
        // })
        if (url.status == 200){
            Toast.fire({
                title: 'Video Url Saved.',
                icon: 'success'
            })
        }
    } catch (error) {
        console.log(error)
        Toast.fire({
            title: 'Error with saving video Url.',
            icon: 'error'
            })
    }
};

function* getVidUrl(action) {
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const vidurl = yield axios.get(`/api/PDFFile/FetchVideoUrl`, config);
        if (vidurl.status == 200){
            yield put({
                type: "SET_VIDS",
                payload: vidurl.data
            })
        }
    } catch (error) {
        console.log(error)
    }
};

function* deleteFile(action){
    yield put({
        type: 'SET_IS_LOADING',
        payload: true
    });
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const delFile = yield axios.delete(`/api/File/DeleteFile/${action.payload.fileId}`, config);
        if (delFile.status == 200){
            const files = yield axios.get(`/api/File/GetAllFilesForCompany/${action.payload.companyId}/00000000-0000-0000-0000-000000000000/0/25`, config);
            yield put({
                type: 'SET_COMPANY_FILES',
                payload: files.data
            });
            yield put({
                type: 'SET_IS_LOADING',
                payload: false
            });
        }
    } catch (error) {
        console.log(error)
    }
};

function* uploadAppFile(action) {
    const token = getToken();
    const user = SplitToken();
    let fileInfo = [];
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const formData = new FormData();
        formData.append("file", action.payload.file);
        formData.append("companyId", action.payload.companyId);
        formData.append("name", action.payload.name);
        const file = yield axios.post(`/api/AppVersionFile/AppVersionFileUpload`, formData , config);
        if (file.status === 200){
            Toast.fire({ title: 'App Uploaded', icon: 'success' });
            action.setFile([]);
            action.navigate("/AppManagement")
            yield put({
                type: "GET_ALL_APPS"
            })
        }

    } catch (error) {
        console.log("Upload file error:" + error)
        Toast.fire({ title: 'Error in upload', icon: 'error' });
    }
};

function* getAllApps(action){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const files = yield axios.get(`/api/AppVersionFile/FetchAppUrls`, config);
        if (files.status === 200){
            yield put({
                type: "SET_APPS",
                payload: files.data
            })

        }

    } catch (error) {
        console.log("Fetch App file error:" + error)
    }
}

function* deleteAppUrl(action){
    const token = getToken();
    const user = SplitToken();
    const config = {
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": 'application/json',
            "Content-Type": 'application/json',
            "Access-Control-Allow-Origin": 'https://*, http://*' 
        }
    };
    try {
        const file = yield axios.delete(`/api/AppVersionFile/DeleteAppUrl/${action.payload}`, config);
        if (file.status === 200){
            yield put({
                type: "GET_ALL_APPS"
            })
        }

    } catch (error) {
        console.log("Fetch App file error:" + error)
    }
}

function* downloadApp(action) {
    const token = getToken();

    const config = {
        responseType: "arraybuffer", 
        headers: { 
            Authorization: `Bearer ${token}`,
            "Accept": "arraybuffer",
            "Content-Type": "arraybuffer",
            "Access-Control-Allow-Origin": 'https://*, http://*' ,
        },
    };

    const ZIPfile = yield axios.get(`/api/AppVersionFile/AppDownload/${action.payload.id}`, config)
        // .then((res) => { 
            // console.error("RES",res)
            // fileDownload(res.data, `${action.payload.appVersion}`);

            const zipfFile = new Blob(
                [ZIPfile.data], 
                {type: 'arraybuffer'}
            );
            const ZIPURL = URL.createObjectURL(zipfFile);
            const a = document.createElement('a');
            a.href = ZIPURL;
            a.download = `${action.payload.appVersion}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(ZIPURL);

};

function* fileSaga() {
    yield takeLatest('UPLOAD_FILE', uploadFile);
    yield takeLatest('UPLOAD_TEST', testUpload);
    yield takeLatest('GET_COMPANY_FILES', getAllFiles);
    yield takeLatest('GET_COMPANY_PAID_FILES', getAllPaidFiles);
    yield takeLatest('GET_VALIDATION_FILE', getValidation);
    yield takeLatest('ACCEPT_FILE', acceptFile);
    yield takeLatest('BUY_FILE', buyFile);
    yield takeLatest('DOWNLOAD_PAID', downloadPaid);
    yield takeLatest('DOWNLOAD_VALIDATION', downloadValidation);
    yield takeLatest('UPDATE_FILE_NAME', changeFileName);
    yield takeLatest('UPLOAD_PDF_FILE', uploadPDFFile);
    yield takeLatest('GET_PDF_FILE', getPDFFile);
    yield takeLatest('SET_CUSTOMER_FOR_FILE', updateFileWithCustomer);
    yield takeLatest('GET_CUSTOMER_FILES', getAllFilesforCustomer);
    yield takeLatest('POINT_COUNT_POST', postCountPost);
    yield takeLatest('FETCH_PROCESSOR_VERSION', fetchProcessorVersion);
    yield takeLatest('UPLOAD_VIDEO_URL', uploadVideoUrl);
    yield takeLatest('GET_VIDEO_URLS', getVidUrl);
    yield takeLatest('DELETE_FILE', deleteFile);
    yield takeLatest('APP_UPLOAD', uploadAppFile);
    yield takeLatest('GET_ALL_APPS', getAllApps);
    yield takeLatest('DELETE_APP', deleteAppUrl);
    yield takeLatest("DOWNLOAD_APP", downloadApp);
};

export default fileSaga;