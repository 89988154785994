import React, { useEffect } from 'react';
import {
    Box,
    Button,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";

function AppDownload(){
    ///// Download Botmark Apps based on Super Admin Uploaded app versions /////
    const appUrls = useSelector((state) => state.FileReducer?.appUrls);
    const dispatch = useDispatch();
    const [markedVersion, setMarkedVersion] = React.useState();

    let highestVersion = {};

    // let data = appUrls;
    function FindHighest(){
        highestVersion = appUrls?.reduce((a, b) =>
            0 < a?.appVersion.localeCompare(b?.appVersion, undefined, { numeric: true, sensitivity: 'base' })
                ? a
                : b
        )
        console.log(highestVersion)
        setMarkedVersion(highestVersion)
    }

    React.useEffect(() => {
        dispatch({ type: 'GET_ALL_APPS' })

        if (appUrls.length != 0){
            FindHighest()
        }
    },[]);

    return (
        <Box sx={{margin: "5%"}}>
            <h2>Botmark Application Download</h2>
            <ul className="processorList" style={{padding: 'inherit'}}>
                { appUrls?.map((app, i) => {
                        return (
                            <div key={i} i={i} className="processorIndex">
                                { app?.appVersion == markedVersion?.appVersion ?
                                <div style={{border: "green solid 2px", borderRadius:'5px'}}>
                                    <p style={{color:'green'}}>LATEST</p>
                                    <h3>{app?.appVersion.split(".Installer.zip")}</h3>
                                    {/* <Button href={app?.url} >Download</Button> */}
                                    <Button onClick={() => dispatch({ type: 'DOWNLOAD_APP', payload: app })} >Download</Button>
                                </div>
                                :
                                <div>
                                    <h3>{app?.appVersion.split(".Installer.zip")}</h3>
                                    {/* <Button href={app?.url} >Download</Button> */}
                                    <Button onClick={() => dispatch({ type: 'DOWNLOAD_APP', payload: app })} >Download</Button>
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </ul>
        </Box>
    )
};

export default AppDownload;
