import React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import {
    Box,
    Typography,
    Button,
    Hidden,
    Modal,
    Chip,    
} from '@mui/material';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport
} from '@mui/x-data-grid';
import {
    Wifi,
    WifiOff,
} from 'react-feather';
import RulerNotesModal from '../rulers/RulerInfoModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const CompanyRulerDataGrid = () => {
    /// Company data grid for super admins shows all registered companies
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    // const role = useSelector((state) => state.CompanyReducer.userRole);
    // const users = useSelector((state) => state.CompanyReducer.usersInCompany);
    // const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    const allRulers = useSelector((state) => state.RulerReducer.allRulers);
    const [rowItem, setRowItem] = useState();
    const [editRowsModel, setEditRowsModel] = React.useState({});
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [open2, setOpen2] = React.useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    function showInfo(cellValues) { // hadles the double click to bring up modal to edit crop amount
        setRowItem({
            id: cellValues.row.id,
            rulerNotes: cellValues.row.rulerNotes,
            rulerSerialNumber: cellValues.row.rulerSerialNumber,
        })
        handleOpen1();
    };

    const columns = [
        { field: 'rulerSerialNumber', headerName: 'Ruler#', flex: .5, headerClassName: 'header' },
        { field: 'companyName', headerName: 'Company', flex: .5, headerClassName:'header',
            renderCell: (cellValues) => {
                if(cellValues.row.companyName){
                    return(<span>{cellValues.row.companyName}</span>)
                } else {
                    return(<span>N/A</span>)
                }
            }
        },
        { field: 'warrantyDate', headerName: 'Warranty', flex: .5, headerClassName:'header',
            renderCell: (cellValues) => {
                let date = new Date(cellValues.row.warrantyDate);
                // return date.toLocaleString()
                if(cellValues.row.warrantyDate){
                    return(<span onClick={() => editRulerDate(cellValues)}>{date.toLocaleDateString()}</span>)
                } else {
                    return(<span onClick={() => editRulerDate(cellValues)}>N/A</span>)
                }
            }
        },
        { field: 'rulerNotes', headerName: 'Notes', flex: .75, headerClassName: 'header',
            renderCell: (cellValues) => {
                if(cellValues.row.rulerNotes){
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis', }}>{cellValues.row.rulerNotes}</Button>)
                } else {
                    return(<Button onClick={() => showInfo(cellValues)} style={{justifyContent:'flex-start', overflow: 'hidden', textOverflow: 'ellipsis'}}>No Notes</Button>)
                }
            }
        }
    ];

    const handleOpen = () => setOpen(!open);
    const handleCheck = () => setCheck(!check);

    useEffect(() => {
    }, []);

    return (
        <Page title="Companies">
            <Box p={3} sx={{ '& .header': { backgroundColor: '#e2e2e2' } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h3' textAlign='left' p={2}>
                        Rulers
                    </Typography>
                </Box>
                <DataGrid
                    columns={columns}
                    rows={allRulers}
                    sx={{ height: '70vh' }}
                />
                <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <RulerNotesModal item={rowItem} style={style} handleClose1={handleClose1} handleClose2={handleClose2}/>
                </Modal>
            </Box>
        </Page>
    )
};

export default CompanyRulerDataGrid;