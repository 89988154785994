import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    Typography,
    FormControl,
    Button,
    TextField,
} from "@mui/material";
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function RulerDateModal({item, style}){
  const dispatch = useDispatch();
  /// displays message from customer for processor
  const [rulerNumber, setRulerNumber] = React.useState(item.rulerSerialNumber);


  const endDateInitialState = dayjs().endOf('day');
  const [endDate, setEndDate] = React.useState(endDateInitialState);
  const [exportEndDate, setExportEndDate] = React.useState(dayjs(endDateInitialState).format());


  let rulerInfo = {
    Id: item.id,
    RulerNotes: item.rulerNotes,
    RulerSerialNumber: item.rulerSerialNumber,
    WarrantyDate: endDate,
    UserId: item.userId
  }

  function submitDate(){
    dispatch({ type: "UPDATE_RULER", payload: rulerInfo });
  };

  React.useEffect(() => {

  },[]);

    return(
        <Box sx={style} style={{display: 'flex', justifyContent: 'space-between', justifyContent:'center' }}>
          <Box style={{flex:1, width:'100%'}}>
            <Typography id="modal-modal-title" variant="h5" component="h2" style={{textAlign:'center'}}>
              Ruler Serial Number
            </Typography>
            <p style={{textAlign:'center'}}>{item.rulerSerialNumber}</p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

            <div style={{display:'flex', flexDirection:'column', width:'100%',}}>
                <Box sx={{ m: 1 }} >
                    <DatePicker
                        className='DatePicker'
                        label="End Date"
                        value={endDate}
                        onChange={ (selectedValue) => {
                            setEndDate(dayjs(selectedValue).endOf('day'));
                            setExportEndDate(dayjs(selectedValue).endOf('day').format());
                        }}
                        renderInput={ (params) => <TextField {...params} />}
                    />
                </Box>
                <Button onClick={() => submitDate()}>Update Ruler</Button>
            </div>
            </LocalizationProvider>
          </Box>
        </Box>
    )
}

export default RulerDateModal;