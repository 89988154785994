import React from 'react';
import { useEffect, useState } from "react";
import {
    Button,
    FormControl,
    OutlinedInput,
    InputAdornment,
    InputLabel,
    Select,
    TextField,
    MenuItem
} from '@mui/material';
import { Form, FormGroup, Label, Input, FormText, Dropdown } from 'reactstrap';
import { Check, PlusSquare } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Toast from '../../components/Toast';

function RulerTab(selectedCompProp){
    const userCompany = useSelector((state) => state.CompanyReducer.userCompanies[0]);
    const allRulers = useSelector((state) => state.RulerReducer.allRulers);
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(null);
    const [serialNumber, setSerialNumber] = React.useState();
    const [notes, setNotes] = React.useState('');
    console.log(allRulers)
    // const allCompanies = useSelector((state) => state.CompanyReducer.allCompanies);
    // const companyUsers = useSelector((state) => state.CompanyReducer.usersInCompanyMenu);
    // const [selectedComp, setSelectedComp] = React.useState(selectedCompProp.selectedCompProp);
    // const [selectedUser, setSelectedUser] = React.useState('00000000-0000-0000-0000-000000000000');

    let rulerData = {
        rulerSerialNumber: serialNumber,
        // rulerNotes: notes,
        userId: userCompany.Id,
    };

    // function setSelectedCompany(event) {
    //     setSelectedComp(event)
    //     dispatch({ type: 'GET_COMPANY_USERS', payload: { event: event, type: 2 } });
        // dispatch({ type: 'EDIT_EMPLOYEE_ROLE', payload: {UserId: item.userId, RoleName: 'Downloader Admin', CompanyId: company.id}});
    // };

    ///create ruler
    function submitRuler() {

        // if (serialNumber == null || serialNumber.match(/[E][v][a][n][G][3][-]\d\d\d\d\d\d/gm) == null) {
        //     Toast.fire({
        //         title: 'Check Serial Number',
        //         icon: 'error'
        //     })
        // } else {
            let numbArray = serialNumber.split('/')

            for (let i = 0; i < numbArray?.length; i++) {
                console.error(numbArray[i])
                rulerData = {
                    rulerSerialNumber: `EvanG3-${numbArray[i]}`,
                    // rulerNotes: notes,
                    userId: userCompany.id
                }
                
                dispatch({ type: "ASSIGN_RULER", payload: rulerData });
            }
            
            // setSerialNumber('');
            // setNotes('');
            // dispatch({ type: "POST_RULER", payload: rulerData });

            // console.log(rulerData)
        // }
    };

    function clearRulerData(){
        setNotes('');
        setSerialNumber();
        // setSelectedUser('00000000-0000-0000-0000-000000000000');
        // setSelectedComp(selectedCompProp.selectedCompProp);
    }

    useEffect(() => {
        if (selectedCompProp.selectedCompProp != "00000000-0000-0000-0000-000000000000"){
            dispatch({ type: 'GET_RULERS_FOR_COMPANY', payload: userCompany.id});
        }
    }, []);

    return(
        <div>
            <h1>Ruler Management</h1>
            <Form style={{ width: "100%", margin: 'auto' }}>
                <Label>Enter all Rulers Purchased by <u>{userCompany.name}</u> Into the Botmark Management System.</Label>
                <br />
                <div style={{ display: 'flex', flexDirection:'column'}}>
                    <FormControl sx={{ m: 1 }} style={{ padding: 20 }}>
                        <Label style={{ padding: 10 }} htmlFor="outlined-adornment-amount"><b>Ruler Serial Number #</b></Label>
                        <Label style={{ padding: 10 }} htmlFor="outlined-adornment-amount">Please enter the last 6 digits of the Serial Number exactly as it appears on the EvanG3 Electronic Detailer. If you are entering more than one ruler please separate with a / .</Label>
                        <Label style={{ padding: 10 }} htmlFor="outlined-adornment-amount">Example: 000000 or 000000/000001</Label>
                        <TextField
                            style={{ width: '50%', alignSelf: 'center' }}
                            multiline rows={8} 
                            id="outlined-adornment-amount"
                            onChange={(event) => setSerialNumber(event.target.value)}
                            />
                    </FormControl>
                </div>
                <Label>Warning: The system will not allow you to submit a ruler already in use.</Label>
                <br />
                <br />
                <Button color="primary" variant="contained" onClick={() => submitRuler()}>
                    Claim Ruler(s)
                </Button>
            </Form>
            <br/>
            {allRulers.map(item => (
                <div>
                    <span><b>Ruler Serial #:</b> {item?.rulerSerialNumber}</span>
                    { item?.warrantyDate &&
                        <span> / <b>Warranty Date:</b> {new Date(item?.warrantyDate).toLocaleDateString()}</span>
                    }
                </div>
            ))}
        </div>
    )
};

export default RulerTab;